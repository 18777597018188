import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserListMutation } from "../../Auth/APIReducer";

import SearchIcon from "@mui/icons-material/Search";

function UserList() {
  const [userList, Data] = useUserListMutation();

  const [expend, setexpend] = useState(false);

  const [Input, setInput] = useState("");

  const { isError, isSuccess, data } = Data;

  useEffect(() => {
    if (isError) {
      toast.info("No Data Found!");
    }
  }, [isError]);

  useEffect(() => {
    userList("");
  }, []);

  return (
    <>
      <div className="m-5 bg-slate-300 rounded-xl">
        <div className="w-full flex justify-end items-center p-5">
          {/* Search Box */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              userList(Input);
            }}
            className="flex items-center h-9 rounded-md overflow-hidden text-slate-900"
          >
            {/* Search Icon */}
            <div className="grid place-items-center h-9 w-9">
              <SearchIcon
                className="h-5 w-5"
                onMouseOver={() => setexpend(true)}
              />
            </div>

            {/* Search Field */}
            <input
              type="text"
              placeholder="Type to search..."
              value={Input}
              onChange={(e) => setInput(e.target.value)}
              className={`text-xs h-full ease-in-out transition-all duration-700 bg-transparent border-b-2 border-slate-800 ${
                expend ? "w-full" : "w-0"
              } outline-none text-slate-900 placeholder:text-slate-900`}
            />
          </form>
          {/* <button
            className="focus:ring-2 focus:ring-offset-2 focus:ring-slate-800 mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-slate-600 hover:bg-slate-800 focus:outline-none rounded"
            onClick={() => History.push("/registration")}
          >
            <p className="text-sm font-medium leading-none text-white">
              Add User
            </p>
          </button> */}
        </div>
        <div className="bg-gray-100 pt-2 overflow-x-scroll rounded-b-xl 2xl:overflow-hidden">
          <table className="w-full text-sm text-left  text-slate-800">
            <thead className="">
              <tr className="drop-shadow-sm border-b-2 text-slate-900">
                <th className="py-2 px-6" scope="col">
                  Name
                </th>
                <th className="py-2 px-6" scope="col">
                  Email
                </th>
                <th className="py-2 px-6" scope="col">
                  Phone
                </th>
                <th className="py-2 px-6" scope="col">
                  Address
                </th>
                <th className="py-2 px-6" scope="col">
                  Created Date
                </th>
                <th className="py-2 px-6" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="w-full text-sm bg-white">
              {isSuccess &&
                data.data.map((value) => {
                  return (
                    <tr key={value.user_id} className="border-b">
                      <td className="py-3 px-6">{value.user_name}</td>
                      <td className="py-3 px-6">{value.email}</td>
                      <td className="py-3 px-6 text-center xl:text-start">
                        +{`${value.mobile_number}`.split("-")[0]}{" "}
                        {`${value.mobile_number}`.split("-")[1]}
                      </td>
                      <td className="py-3 px-6">{value.address}</td>
                      <td className="py-3 px-6">
                        {new Date(value.created_at).toLocaleString()}
                      </td>
                      <td className="py-3 px-6">
                        <NavLink
                          className="bg-blue-100 hover:bg-blue-400 hover:text-white text-blue-800 text-sm font-medium mr-2 px-2.5 py-1 rounded"
                          to={`/info/${value.user_id}`}
                        >
                          View
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default UserList;
