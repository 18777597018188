import { Switch, Route } from "react-router-dom";
import "./App.css";

import Login from "./Components/Login/Login";

import ProtectedRoute from "./Auth/ProtectedRoute";
import Routes from "./Routes";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />

        {Routes.map((route, index) => {
          return (
            <ProtectedRoute
              key={index}
              exact
              path={route.path}
              Component={route.component}
            />
          );
        })}
      </Switch>
    </>
  );
}

export default App;
