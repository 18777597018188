import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDeviceRegisterMutation } from "../../Auth/APIReducer";
import Validator from "validator";
import { toast } from "react-toastify";

function AddDevice() {
  const { id } = useParams();

  const [deviceRegister, res] = useDeviceRegisterMutation();

  const { isLoading, isSuccess, isError } = res;

  const History = useHistory();

  const [Device, setDevice] = useState({
    user_id: id,
    mac_address: "",
    device_name: "",
    company_name: "",
    rated_current: "",
    rated_voltage: "",
    max_current: "",
    max_voltage: "",
    max_power: "",
    year_consumption: "123",
  });

  const ChangeHandler = (e) => {
    const Value = e.target.value;

    setDevice({
      ...Device,
      [e.target.name]: Value,
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();

    deviceRegister(Device);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Device Add Successfully");
      History.push(`/info/${id}`);
    } else if (isError) {
      toast.error("Please Enter Valid Data");
    }
  }, [isError, isSuccess]);

  return (
    <>
      {isLoading && (
        <div className="load-modal">
          <div className="loader1 mt-4"></div>
        </div>
      )}

      <div className="m-5">
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="lg:grid lg:grid-cols-3 lg:gap-6">
            <div className="lg:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-white">
                  Device Information
                </h3>
                <p className="mt-1 text-sm text-slate-200">
                  Please Enter Device Data Carefully.
                </p>
              </div>
            </div>
            <div className="mt-5 lg:mt-0 lg:col-span-2">
              <form onSubmit={SubmitHandler}>
                <div className="shadow overflow-hidden rounded-lg">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          AC Device Name
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="device_name"
                          value={Device.device_name}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          AC Brand Name
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="company_name"
                          value={Device.company_name}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          EMONE Device ID
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="mac_address"
                          value={Device.mac_address}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_.-:`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Maximum Power
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="max_power"
                          value={Device.max_power}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Rated Current
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="rated_current"
                          value={Device.rated_current}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Rated Voltage
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="rated_voltage"
                          value={Device.rated_voltage}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Maximum Current
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="max_current"
                          value={Device.max_current}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Maximum Voltage
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="max_voltage"
                          value={Device.max_voltage}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDevice;
