import React, { useEffect, useState } from "react";
import { useAllDeviceQuery, useUserListMutation } from "../../Auth/APIReducer";
import Dashcard from "./Dashcard";

function Dashboard() {
  const Device = useAllDeviceQuery();
  const [userList, res] = useUserListMutation();

  useEffect(() => {
    userList("");
  }, []);

  const [Data, setData] = useState({
    totalDevice: 0,
    active: 0,
    expired: 0,
    users: 0,
  });

  useEffect(() => {
    if (Device.isSuccess) {
      setData({
        ...Data,
        totalDevice:
          Device.data.active_devices.length +
          Device.data.expired_devices.length,
        active: Device.data.active_devices.length,
        expired: Device.data.expired_devices.length,
      });
    }

    if (res.isSuccess) {
      setData({
        ...Data,
        users: res.data.data.length,
      });
    }
  }, [Device.isSuccess, res.isSuccess]);

  return (
    <>
      <Dashcard
        totalDevice={Data.totalDevice}
        active={Data.active}
        expired={Data.expired}
        user={Data.users}
      />
    </>
  );
}

export default Dashboard;
