import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";

import { EMOneAPI } from "../Auth/APIReducer";

const Store = configureStore({
  reducer: {
    [EMOneAPI.reducerPath]: EMOneAPI.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(EMOneAPI.middleware),
});

setupListeners(Store.dispatch);

export default Store;
