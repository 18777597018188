import React, { useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { NavLink } from "react-router-dom";
import Routes from "../../Routes";

function Sidebar(props) {
  const [open, setOpen] = useState(true);

  return (
    <>
      {/* Main Container */}
      <div className="w-screen h-screen flex relative overflow-hidden bg-slate-800">
        {/* Sidebar */}
        <div
          className={
            open
              ? "relative left-0 w-20 bg-slate-300 transition-all flex flex-col h-screen p-4"
              : "relative left-0 w-60 bg-slate-300 transition-all flex flex-col h-screen p-4"
          }
        >
          {/* Logo Section */}
          <div className="flex items-center h-10 w-full">
            {/* Circle Logo */}
            <div className="h-10 p-1 w-10 rounded-full overflow-hidden mr-2 grid place-items-center bg-slate-800 text-slate-200">
              <img src="https://shunyaekai.tech/image/logo2.png" alt="random" />
            </div>
            {!open && <h3 className="text-sm">EM One</h3>}
          </div>

          {/* Search Box */}
          {/* <div className="flex items-center mt-10 h-9 bg-slate-400 rounded-md overflow-hidden text-slate-900"> */}
          {/* Search Icon */}
          {/* <div className="grid place-items-center h-9 w-9">
              <SearchIcon className="h-5 w-5" />
            </div> */}

          {/* Search Field */}
          {/* <input
              type="text"
              placeholder="Type to search..."
              className="text-xs h-full w-full bg-transparent border-none outline-none text-slate-900 placeholder:text-slate-900"
            /> */}
          {/* </div> */}

          {/* Sidebar Menu */}
          <div className="mt-10">
            <ul>
              {Routes.map((route, index) => {
                if (route.name === "Info" || route.name === "Add Device") {
                  return null;
                }

                return (
                  <NavLink
                    key={index}
                    to={route.path}
                    className="h-10 w-full  rounded-md flex items-center my-2 text-slate-800 cursor-pointer"
                  >
                    <li className="flex ml-3 text-md items-center">
                      {/* list icon */}
                      {route.icon}
                      {/* list name */}
                      {!open && <h3 className="ml-3 text-sm">{route.name}</h3>}
                    </li>
                  </NavLink>
                );
              })}
            </ul>
          </div>

          {/* Sidebar shrink and extend */}
          <div
            className="h-screen w-10 absolute -right-5 top-0 flex items-center"
            onClick={() => setOpen((prev) => !prev)}
          >
            {/* open icon */}
            <div className="h-10 w-10 rounded-full overflow-hidden grid place-items-center bg-slate-300 cursor-pointer">
              {open && <ArrowForwardIosIcon />}
              {/* close icon */}
              {!open && <ArrowBackIosIcon />}
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </>
  );
}

export default Sidebar;
