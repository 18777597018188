import React, { useEffect, useState } from "react";

import { usePostLoginMutation } from "../../Auth/APIReducer";

import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

function Login() {
  const [User, setUser] = useState({
    email: "",
    password: "",
  });

  const [postLogin, res] = usePostLoginMutation(User);

  const History = useHistory();

  const { isLoading, isSuccess, isError, data } = res;

  const Auth = localStorage.getItem("assign");

  const ChangeHandler = (e) => {
    const Value = e.target.value;

    setUser({
      ...User,
      [e.target.name]: Value,
    });
  };

  if (isSuccess) {
    const UserData = data.data;
    localStorage.setItem("assign", JSON.stringify([...UserData[0].role]));
    toast.success("Successfully Sign In");
    History.push("/dashboard");
  }

  useEffect(() => {
    if (Auth) {
      return History.push("/dashboard");
    } else {
      History.push("/");
    }
  }, [Auth, History]);

  useEffect(() => {
    if (isError) {
      toast.error("Invalid Email and Password");
    }
  }, [isError]);

  const SubmitHandler = (e) => {
    e.preventDefault();

    postLogin(User);
  };

  return (
    <>
      {isLoading && (
        <div className="load-modal">
          <div className="loader1 mt-4"></div>
        </div>
      )}
      <div className="h-screen flex">
        <div className="w-full relative flex">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <defs>
              <linearGradient id="myGradient" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="rgb(71 85 105)" />
                <stop offset="95%" stopColor="rgb(15 23 42)" />
              </linearGradient>
            </defs>
            <path
              d="M208.09,0.00 C152.70,67.10 262.02,75.98 200.80,150.00 L0.00,150.00 L0.00,0.00 Z"
              style={{ stroke: "none", fill: "url('#myGradient')" }}
            ></path>
          </svg>

          <div className="-rotate-90 -left-[47%] sm:left-0   sm:rotate-0 absolute top-0 p-8 flex justify-between  w-full items-center">
            <h1 className="text-3xl text-white uppercase font-bold">
              EM-One Plus
            </h1>
          </div>
          <form
            className="absolute mezzo flex flex-col sm:w-2/6 md:w-2/6 lg:w-1/4 xl:w-1/5"
            onSubmit={SubmitHandler}
          >
            <h1 className="text-5xl text-slate-900 font-bold">Login</h1>
            <input
              className="py-2 px-4 border rounded mt-8 text-black  placeholder-black"
              type="email"
              placeholder="Email"
              name="email"
              value={User.email}
              onChange={ChangeHandler}
            />
            <input
              className="py-2 px-4 border rounded mt-4  text-black placeholder-black"
              type="password"
              placeholder="Password"
              name="password"
              value={User.password}
              onChange={ChangeHandler}
            />
            <button
              type="submit"
              className="border-2 bg-slate-600 p-2 text-white hover:bg-slate-900 rounded transition duration-500 ease-in-out font-bold mt-2 uppercase"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
