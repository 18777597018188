import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import Validator from "validator";
import {
  useCountryListMutation,
  useDeviceRegisterMutation,
  useUserRegisterMutation,
} from "../../Auth/APIReducer";

function AddData() {
  const [ReadOnly, setReadOnly] = useState(true);

  const [userRegister, res] = useUserRegisterMutation();

  const [deviceRegister, deviceRes] = useDeviceRegisterMutation();

  const [countryList, countryData] = useCountryListMutation();

  const { isLoading, isSuccess, isError, data } = res;

  const History = useHistory();

  useEffect(() => {
    countryList();
  }, []);

  const [User, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [Device, setDevice] = useState({
    user_id: "",
    mac_address: "",
    device_name: "",
    company_name: "",
    rated_current: "",
    rated_voltage: "",
    max_current: "",
    max_voltage: "",
    max_power: "",
    year_consumption: "123",
  });

  const ChangeHandler = (e) => {
    const Value = e.target.value;

    if (ReadOnly) {
      setUser({
        ...User,
        [e.target.name]: Value,
      });
    } else {
      setDevice({
        ...Device,
        [e.target.name]: Value,
      });
    }
  };

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (ReadOnly) {
      userRegister({
        user_name: `${User.firstname} ${User.lastname}`,
        email: User.email,
        mobile_number: `${User.country.split(" ")[0]}-${User.mobile}`,
        address: `${User.street}, ${User.city}, ${User.state}, ${
          User.country.split(" ")[1]
        } - ${User.zip}`,
      });
    } else {
      deviceRegister(Device);
    }
  };

  useEffect(() => {
    if (deviceRes.isSuccess) {
      toast.success("Registration Successfull");
      History.push("/user-list");
    } else if (deviceRes.isError) {
      toast.error(deviceRes.error.data.message);
    }
  }, [deviceRes.isSuccess, deviceRes.isError]);

  useEffect(() => {
    if (isSuccess) {
      setDevice({
        ...Device,
        user_id: data.id,
      });

      setReadOnly(false);
      toast.success("Add Device Data");
    } else if (isError) {
      toast.error(res.error.data.message);
    }
  }, [isSuccess, isError]);

  return (
    <>
      {(isLoading || deviceRes.isLoading) && (
        <div className="load-modal">
          <div className="loader1 mt-4"></div>
        </div>
      )}
      <div className="mx-10 my-5">
        <div className="mt-10 sm:mt-0">
          <div className="lg:grid lg:grid-cols-3 lg:gap-6">
            <div className="lg:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-white">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-slate-200">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
            </div>
            <div className="mt-5 lg:mt-0  lg:col-span-2">
              <form onSubmit={SubmitHandler}>
                <div className="shadow overflow-hidden rounded-lg">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          First name
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.firstname}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /-./`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error(
                                "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'"
                              );
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Last name
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.lastname}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /-./`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error(
                                "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'"
                              );
                            }
                          }}
                          readOnly={!ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Email address
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.email}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: `"^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error(
                                "Please enter validate email address"
                              );
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Mobile
                        </label>
                        <input
                          type="tel"
                          name="mobile"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.mobile}
                          onChange={(e) => {
                            if (
                              Validator.isNumeric(e.target.value) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use Numerical Only");
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label className="block text-sm font-medium text-gray-700">
                          Street address
                        </label>
                        <input
                          type="text"
                          name="street"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.street}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /-.,/@&()/[]*`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error(
                                "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'"
                              );
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Select Your Country
                        </label>
                        <select
                          name="country"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                          value={User.country}
                          onChange={(e) => {
                            if (e.target.selectedIndex !== 0) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please Select Your Country");
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        >
                          <option>Select Your Country</option>
                          {countryData.isSuccess &&
                            countryData.data.country_list.map((value) => {
                              return (
                                <option
                                  key={value.id}
                                  value={`${value.phonecode} ${value.name}`}
                                >
                                  {value.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.city}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /-./@&()/[]*`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error(
                                "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'"
                              );
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          State / Province
                        </label>
                        <input
                          type="text"
                          name="state"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.state}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /-./@&()/[]*`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error(
                                "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'"
                              );
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          ZIP / Postal code
                        </label>
                        <input
                          type="text"
                          name="zip"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={User.zip}
                          onChange={(e) => {
                            if (
                              Validator.isNumeric(e.target.value) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use Numerical Only");
                            }
                          }}
                          readOnly={!ReadOnly}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="lg:grid lg:grid-cols-3 lg:gap-6">
            <div className="lg:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-white">
                  Device Information
                </h3>
                <p className="mt-1 text-sm text-slate-200">
                  Please Enter Device Data Carefully.
                </p>
              </div>
            </div>
            <div className="mt-5 lg:mt-0 lg:col-span-2">
              <form onSubmit={SubmitHandler}>
                <div className="shadow overflow-hidden rounded-lg">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          AC Device Name
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="device_name"
                          value={Device.device_name}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                        AC Brand Name
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="company_name"
                          value={Device.company_name}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          EMONE Device ID
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="mac_address"
                          value={Device.mac_address}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-:`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Maximum Power
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="max_power"
                          value={Device.max_power}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Rated Current
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="rated_current"
                          value={Device.rated_current}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Rated Voltage
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="rated_voltage"
                          value={Device.rated_voltage}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Maximum Current
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="max_current"
                          value={Device.max_current}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Maximum Voltage
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-black border border-gray-300 rounded-lg bg-white sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="max_voltage"
                          value={Device.max_voltage}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],_-`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              ChangeHandler(e);
                            } else {
                              toast.error("Please use AlphaNumeric");
                            }
                          }}
                          readOnly={ReadOnly}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddData;
