import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserWiseDataMutation } from "../../Auth/APIReducer";

function Info() {
  const { id } = useParams();

  const History = useHistory();

  const [userWiseData, res] = useUserWiseDataMutation();

  const { isLoading, isSuccess, isError, data } = res;

  useEffect(() => {
    if (isError) {
      toast.error(res.error.data.message);
    }
  }, [isError]);

  useEffect(() => {
    userWiseData(id);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="load-modal">
          <div className="loader1 mt-4"></div>
        </div>
      )}
      <div className="bg-slate-300 p-3 m-5 shadow-sm rounded-xl">
        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
          <span clas="text-green-500">
            <svg
              className="h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </span>
          <span className="tracking-wide">About</span>
        </div>
        {isSuccess && (
          <div className="text-gray-700">
            <div className="grid md:grid-cols-2 text-sm">
              <div className="grid text-center sm:text-start sm:grid-cols-2">
                <div className="px-4 py-2 font-semibold">First Name</div>
                <div className="px-4 py-2">
                  {`${data.userInfo.user_name}`.split(" ")[0]}
                </div>
              </div>
              <div className="grid text-center sm:text-start sm:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Last Name</div>
                <div className="px-4 py-2">
                  {`${data.userInfo.user_name}`.split(" ")[1]}
                </div>
              </div>
              <div className="grid text-center sm:text-start sm:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Contact No.</div>
                <div className="px-4 py-2">
                  +91 {`${data.userInfo.mobile_number}`.split("91")}
                </div>
              </div>
              <div className="grid text-center sm:text-start sm:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Email.</div>
                <div className="px-4 py-2">
                  <a
                    className="text-blue-800"
                    href={`mailto:${data.userInfo.email}`}
                  >
                    {data.userInfo.email}
                  </a>
                </div>
              </div>
              <div className="grid text-center sm:text-start sm:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Address</div>
                <div className="px-4 py-2">{data.userInfo.address}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bg-slate-300 shadow-sm rounded-xl m-5 ">
        <div className="flex justify-between items-center p-5">
          <span className="tracking-wide font-semibold">Device Info</span>
          <button
            className="bg-slate-600 hover:bg-slate-800 text-slate-100 hover:text-white font-medium text-sm py-2 px-4 rounded-lg"
            onClick={() => History.push(`/info/${id}/add-device`)}
          >
            Add Device
          </button>
        </div>

        <div className="bg-gray-100 pt-2 overflow-x-scroll rounded-b-xl 2xl:overflow-hidden">
          <table className="w-full text-sm text-left  text-slate-800">
            <thead className="">
              <tr className="drop-shadow-sm border-b-2 text-slate-900">
                <th className="py-2 px-6" scope="col">
                  No.
                </th>
                <th className="py-2 px-6" scope="col">
                  AC Device Name
                </th>
                <th className="py-2 px-6" scope="col">
                  Device Status
                </th>
                <th className="py-2 px-6" scope="col">
                  EMONE Device ID
                </th>
                <th className="py-2 px-6" scope="col">
                  Rated Current
                </th>
                <th className="py-2 px-6" scope="col">
                  Rated Voltage
                </th>
              </tr>
            </thead>
            <tbody className="w-full text-sm bg-white">
              {isSuccess &&
                data.deviceInfo.map((value) => {
                  return (
                    <tr key={value.device_id}>
                      <td className="py-3 px-6">{value.device_id}</td>
                      <td className="py-3 px-6">{value.device_name}</td>
                      <td className="py-3 px-6">{value.device_status}</td>
                      <td className="py-3 px-6">{value.mac_address}</td>
                      <td className="py-3 px-6">{value.rated_current}</td>
                      <td className="py-3 px-6">{value.rated_voltage}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Info;
