import { Route, Redirect } from "react-router-dom";

import React from "react";

import Sidebar from "../Components/UI/Sidebar";
import Navbar from "../Components/UI/Navbar";

function ProtectedRoute({ Component, path, ...rest }) {
  const auth = localStorage.getItem("assign");

  return (
    <>
      <Sidebar>
        <div className="w-full h-screen overflow-y-scroll">
        <div className="w-full h-5 absolute bg-slate-800"></div>
          <Navbar />

          <Route
            {...rest}
            path={path}
            render={(props) => {
              if (auth) {
                return <Component {...props} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/",
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              }
            }}
          />
        </div>
      </Sidebar>
    </>
  );
}

export default ProtectedRoute;
