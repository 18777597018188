import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const EMOneAPI = createApi({
  reducerPath: "EMOneAPI",
  baseQuery: fetchBaseQuery({
    // baseUrl: "http://192.168.1.23:8000/",
    baseUrl: "https://emone.shunyaekai.com/",
    credentials: "include",
  }),

  endpoints: (builder) => ({
    postLogin: builder.mutation({
      query: (data) => ({
        url: "api/em/login",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    endLogout: builder.mutation({
      query: () => ({
        url: "api/em/logout",
        method: "GET",
      }),
    }),

    userRegister: builder.mutation({
      query: (data) => ({
        url: "api/em/user/register",
        method: "POST",
        body: data,
      }),
    }),

    deviceRegister: builder.mutation({
      query: (data) => ({
        url: "api/em/devices/register",
        method: "POST",
        body: data,
      }),
    }),

    userList: builder.mutation({
      query: (data) => ({
        url: `api/em/user/get/${data}`,
        method: "GET",
      }),
    }),

    userWiseData: builder.mutation({
      query: (id) => ({
        url: `api/em/devices/user-devices/${id}`,
        method: "GET",
      }),
    }),

    allDevice: builder.query({
      query: (id) => ({
        url: `api/em/devices/get`,
        method: "GET",
      }),
    }),

    countryList: builder.mutation({
      query: (id) => ({
        url: `api/em/user/country-list`,
        method: "GET",
      }),
    }),

    // device alert notification
    // deviceAlert:builder.query({
    //   quer:(data)=>({
    //     url:,
    //     method:""
    //   })
    // })
  }),
});

export const {
  usePostLoginMutation,
  useEndLogoutMutation,
  useUserRegisterMutation,
  useDeviceRegisterMutation,
  useUserListMutation,
  useUserWiseDataMutation,
  useAllDeviceQuery,
  useCountryListMutation,
} = EMOneAPI;
