import React from "react";

const DeviceAlert = () => {
  return (
    <div className="device-container flex justify-center items-center h-3/4">
      <div
        className="device-box w-6/12 h-60 text-center rounded-2xl p-12"
        style={{ backgroundColor: "#cbd5e1" }}
      >
        <p className="leading-8" style={{ color: "#374151" }}>
          <b>PLEASE CONNECT TO DEVICE'S HOTSPOT NAMED "ENERGY METER"</b>
        </p>
        <p style={{ color: "#374151" }}>
          <b>THEN AFTER SUCCESSFUL CONNECTION PRESS THE "PROCEED" BUTTON</b>
        </p>
        <button
          className="w-28 h-10 bg-black text-white text-sm rounded-md mb-2 mt-5 hover:bg-sky-700 "
          style={{ fontFamily: "sans-serif" }}
        >
          <a href="http://192.168.4.1/" target="_blank">
            PROCEED
          </a>
        </button>
      </div>
    </div>
  );
};

export default DeviceAlert;
