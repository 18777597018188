import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useEndLogoutMutation } from "../../Auth/APIReducer";

import { toast } from "react-toastify";

function Navbar() {
  const location = useLocation();

  const routes = location.pathname.split("/").slice(1);

  const History = useHistory();

  const [endLogout, res] = useEndLogoutMutation();

  const { isLoading, isSuccess, isError } = res;

  if (isSuccess) {
    localStorage.removeItem("assign");
    toast.success("Successfully Logout");
    return History.push("/");
  }

  if (isError) {
    return toast.warn("Something Went Wrong...");
  }

  let pathname = "";

  return (
    <>
      {isLoading && (
        <div className="load-modal">
          <div className="loader1 mt-4"></div>
        </div>
      )}
      <nav
        className="flex justify-between top-3 z-10 sticky mx-5 mt-5 py-3 px-3 sm:px-5 text-gray-700 bg-slate-300 rounded-lg border border-gray-200"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1">
          <li className="inline-flex items-center">
            <Link
              to="/dashboard"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              <svg
                className="mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </Link>
          </li>
          {routes.map((route, index) => {
            pathname += `/${route}`;

            return (
              <li key={index}>
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <Link
                    to={pathname}
                    className="ml-1 text-xs sm:text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-1"
                  >
                    {route[0].toUpperCase() + route.slice(1, route.length)}
                  </Link>
                </div>
              </li>
            );
          })}
        </ol>
        <ol>
          <li
            className="ml-1 text-sm font-medium text-gray-700 hover:text-red-600 md:ml-2 cursor-pointer"
            onClick={() => endLogout()}
          >
            <PowerSettingsNewIcon />
          </li>
        </ol>
      </nav>
    </>
  );
}

export default Navbar;
