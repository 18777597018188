import React from "react";

import DevicesIcon from "@mui/icons-material/Devices";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import OnDeviceTrainingIcon from "@mui/icons-material/OnDeviceTraining";
import PersonIcon from "@mui/icons-material/Person";

function DashCard(props) {
  return (
    <div className="items-center px-2 py-4 mt-5">
      <div className="flex flex-wrap justify-center pb-3 mx-4 md:mx-24 lg:mx-0">
        <div className="w-full p-2 xl:w-1/5 lg:w-4/12 md:w-1/2">
          <div className="flex flex-row justify-between px-3 py-8 sm:px-6 sm:py-10 overflow-hidden bg-white  hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
            <div className="flex flex-row justify-between items-center">
              <div className="p-3 sm:p-4 bg-gray-300  rounded-xl bg-opacity-30">
                <DevicesIcon className="h-6 w-6 group-hover:text-gray-50" />
              </div>
            </div>
            <div>
              <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-gray-700 group-hover:text-gray-50">
                {props.totalDevice}
              </h1>
              <p>Total Devices</p>
            </div>
          </div>
        </div>

        <div className="w-full p-2 xl:w-1/5 lg:w-4/12 md:w-1/2">
          <div className="flex flex-row justify-between px-3 py-8 sm:px-6 sm:py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
            <div className="flex flex-row justify-between items-center">
              <div className="p-3 sm:p-4 bg-gray-300  rounded-xl bg-opacity-30">
                <OnDeviceTrainingIcon className="h-6 w-6 group-hover:text-gray-50" />
              </div>
            </div>
            <div>
              <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-gray-700 group-hover:text-gray-50">
                {props.active}
              </h1>
              <p>Active Devices</p>
            </div>
          </div>
        </div>

        <div className="w-full p-2 xl:w-1/5 lg:w-4/12 md:w-1/2">
          <div className="flex flex-row justify-between px-3 py-8 sm:px-6 sm:py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
            <div className="flex flex-row justify-between items-center">
              <div className="p-3 sm:p-4 bg-gray-300  rounded-xl bg-opacity-30">
                <DeviceUnknownIcon className="h-6 w-6 group-hover:text-gray-50" />
              </div>
            </div>
            <div>
              <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-gray-700 group-hover:text-gray-50">
                {props.expired}
              </h1>
              <p>Unused Devices</p>
            </div>
          </div>
        </div>

        <div className="w-full p-2 xl:w-1/5 lg:w-4/12 md:w-1/2">
          <div className="flex flex-row justify-between px-3 py-8 sm:px-6 sm:py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
            <div className="flex flex-row justify-between items-center">
              <div className="p-3 sm:p-4 bg-gray-300  rounded-xl bg-opacity-30">
                <PersonIcon className="h-6 w-6 group-hover:text-gray-50" />
              </div>
            </div>
            <div>
              <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-gray-700 group-hover:text-gray-50">
                {props.user}
              </h1>
              <p>Users</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashCard;
