// Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
// Components
import Dashboard from "./Components/Dashboard/Dashboard";
import AddData from "./Components/Data/AddData";
import UserList from "./Components/Data/UserList";
import Info from "./Components/Data/Info";
import AddDevice from "./Components/Data/AddDevice";
import DeviceAlert from "./Components/DeviceAlert";
const Routes = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
    component: Dashboard,
  },

  {
    name: "User List",
    icon: <ListAltIcon />,
    path: "/user-list",
    component: UserList,
  },

  {
    name: "Registration",
    icon: <DataSaverOnIcon />,
    path: "/registration",
    component: AddData,
  },

  {
    name: "Info",
    icon: <DataSaverOnIcon />,
    path: "/info/:id",
    component: Info,
  },
  {
    name: "Add Device",
    icon: <DataSaverOnIcon />,
    path: "/info/:id/add-device",
    component: AddDevice,
  },
  {
    name: "Device",
    icon: <SpeakerPhoneIcon />,
    path: "/device-alert",
    component: DeviceAlert,
  },
];

export default Routes;
